<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
        class="breadcrumb-area ptb--60 bg_image bg_dark"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Projekte</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
      </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Testimonial Area  -->
    <div class="rn-testimonial-area rn-section-gap">
      <v-container>
        <Projects></Projects>
<!--        <Testimonial />-->
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import Testimonial from "../components/testimonial/Testimonial";
  import Brand from "../components/brand/Brand";
  import Footer from "../components/footer/Footer";
  import Projects from "../components/projects/Projects";
  export default {
    components: {
      Projects,
      Header,
      Brand,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Projekte",
            to: "",
            disabled: true,
          },
        ],
      };
    },

    methods: {},
  };
</script>
