<template>
  <div>
    <v-row class="pb--20">
      <v-col class="mb-12">
        <h2 class="heading-title">Ausgewählte Projekte.</h2>
        <p class="description mt-13">
          Hier erhältst du einen Einblick in Projekte und Applikationen, die wir bereits mit Kunden umsetzen durften.
        </p>
      </v-col>
    </v-row>
    <div
        class="gallery-wrapper gallery-grid mesonry-list grid-metro3"
        id="animated-thumbnails"
    >

      <div v-for="item in projects" class="item-portfolio-static image masonry_item portfolio-33-33 ">
        <router-link :to="item.href">
          <div class="portfolio-static">
            <div class="thumbnail-inner">
              <div class="thumbnail">
                <img :src="item.thumb" alt="Service image"/>
              </div>
            </div>

            <div class="content">
              <div class="inner">
                <h4>{{ item.title }}</h4>
                <p>{{ item.description }}</p>

              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects:
          [
            // {
            //   thumb: require("../../assets/images/portfolio/dp-portfolio-01.jpg"),
            //   src: require("../../assets/images/portfolio/big/dp-big--portfolio-01.jpg"),
            //   description: "Applikation für die Fahrzeugwartung der Berufsfeuerwehr St. Gallen",
            //   title: `Webapplikation Fahrzeugwartung`,
            //   href: '/projects/project-1'
            // }
            // ,
            {
              thumb: require("../../assets/images/portfolio/deutschkurse-1.png"),
              description: "Webapplikation zur Verwaltung von Deutschkursangeboten im Kanton St. Gallen",
              title: `Kanton St. Gallen`,
              href: '/projects/deutschkursplattform'
            }
          ]
    }
  }
};


</script>
